.app{
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.intro{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}
.intro :nth-child(1)>:nth-child(1){
  text-decoration: underline;
  font-size: 1.5rem;
}

.email{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.email >a{
  color: orange;
}
.email>:nth-child(1){
  width: 25px;
}

.phone{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.phone>:nth-child(1){
  width: 20px;
}

.location{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.location>:nth-child(1){
  width: 25px;

}

.image>img{
  width: 10rem;
  border-radius: 99px;
}

/* Summary */
.summary>p{
  text-align: justify;
}

/* skills */
.skills>:nth-child(3){
  font-weight: bold;
  font-size: 1.1rem;
}

.skills>:nth-child(2){
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Projects */
.projects>:nth-child(3){
  text-align: justify;
}
.projects>:nth-child(2){
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.project-links{
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Future Work */
.future-goals>:nth-child(2){
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.future-goals :nth-child(4){
  padding-left: 3rem;
  font-weight: bold;
  font-size: 1.1rem;
  padding-top: 1rem;
}


@media screen and (max-width: 500px) {

  .intro{
    flex-direction: column-reverse;
  }

}